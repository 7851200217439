import {Link, useNavigate} from '@remix-run/react';
import {Fragment} from 'react';
import {twMerge} from 'tailwind-merge';

export interface BreadcrumbProps {
  items: Array<{
    title: string | React.ReactNode;
    to?: string;
    navigateBack?: boolean;
  }>;
  className?: string;
}

export function Breadcrumb({items, className}: BreadcrumbProps) {
  const navigate = useNavigate();

  return (
    <p className={twMerge('text-gray-600 font-medium text-sm', className)}>
      {items.map((item, i) => {
        if (item.to) {
          return (
            <Fragment key={i}>
              <Link
                onClick={(e) => {
                  if (item.navigateBack) {
                    e.preventDefault();
                    navigate(-1);
                  }
                }}
                to={item.to}
                className="hover:underline decoration-gray-600 underline-offset-1"
              >
                {item.title}
              </Link>
              {i !== items.length - 1 ? ' / ' : ''}
            </Fragment>
          );
        }

        return (
          <Fragment key={i}>
            <span>{item.title}</span>
            {i !== items.length - 1 ? ' / ' : ''}
          </Fragment>
        );
      })}
    </p>
  );
}
